/* eslint no-unused-vars: 0  */
/* global $ */

// const animationBreakpoint = 960; // see matching breakpoint in global/effects/animations.scss
const hasIOSupport =
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype;

$.$body = $('body');
const $timelineWrapper = $.$body.find('.timeline-events-wrapper');

if (hasIOSupport && $timelineWrapper.length) {
  const $allSlides = $.$body.find('.slide');

  const setActiveClass = (entries, observer) => {
    let mostIntersected = -1;
    let mostIntersection = -1;

    entries.forEach((entry) => {
      const { isIntersecting, target, intersectionRatio } = entry;
      const $target = $(target);
      if (isIntersecting && intersectionRatio > 0.01) {
        $target
          .addClass('visibleSlide')
          .data('ir', intersectionRatio)
          .attr('data-ir', intersectionRatio);
      } else {
        $target
          .removeClass('activeSlide visibleSlide')
          .data('ir', 0)
          .attr('data-ir', 0);
      }
    });

    const $activeSlides = $allSlides
      .filter('.visibleSlide')
      .removeClass('activeSlide');

    const $mostIntersected = $activeSlides.each((i, el) => {
      const ir = $(el).data('ir') || 0;
      if (ir > mostIntersection) {
        mostIntersection = ir;
        mostIntersected = i;
      }
      // console.log({ ir, i, mostIntersected, mostIntersection });
    });

    if (mostIntersected > -1) {
      $mostIntersected.eq(mostIntersected).addClass('activeSlide');
      if ($mostIntersected.eq(mostIntersected).hasClass('first-slide')) {
        $timelineWrapper.addClass('at-first-slide');
      } else if ($mostIntersected.eq(mostIntersected).hasClass('last-slide')) {
        $timelineWrapper.addClass('at-last-slide');
      } else {
        $timelineWrapper.removeClass('at-first-slide at-last-slide');
      }
    }
  };

  // let activeObserver = null;
  // window.activeObserver = activeObserver;
  // const rootNode = $.$body.hasClass('single-landing') ? $('#main_content').get(0) : undefined;

  const addActiveAtThreshold = (node) => {
    if (!window.activeObserver) {
      window.activeObserver = new IntersectionObserver(setActiveClass, {
        root: $.$body.find('.timeline-side').get(0),
        rootMargin: '0px',
        threshold: [0, 0.1, 0.5, 0.9, 1], // fire on top and bottom edges
      });
    }
    window.activeObserver.observe(node);
  };

  // the percentage visible a module must be to load
  const defaultVisibleThreshold = 0.2;

  $.$body.find('.timeline-side .slide').each((i, thisModule) => {
    const maxThreshold = window.innerHeight / thisModule.offsetHeight;
    let threshold =
      $(thisModule).data('module-seen-threshold') || defaultVisibleThreshold;
    if (threshold > maxThreshold) {
      threshold = maxThreshold;
    }

    addActiveAtThreshold(thisModule);
  });
}
