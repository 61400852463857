/* global $ */
/* eslint-disable */
//TIMELINE PAGE

// VARIABLES
var timeline = document.querySelector('.timeline ol'),
  timelineSection = document.querySelector('#timeline-section'),
  $tS = $(timelineSection),
  elH = document.querySelectorAll('.timeline li > div'),
  arrows = document.querySelectorAll('.timeline .arrows .arrow'),
  arrowPrev = document.querySelector('.timeline .arrows .arrow__prev'),
  arrowNext = document.querySelector('.timeline .arrows .arrow__next'),
  firstItem = document.querySelector('.timeline .timeline-intro'),
  lastItem = document.querySelector('.timeline li:last-child'),
  xScrolling = ~~(window.innerWidth / 1.5 - 100),
  disabledClass = 'disabled';

//throttle, but only fires on trailing edge, after delay of no similar events
var throttleAfter = function(fn, ms, scope) {
  ms = ms || 0;
  var timer = 0;
  return function(e) {
    var context = scope || this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, ms);
  };
};

var checkGradientTimer = null;
var handleTLScroll = throttleAfter(function(e) {
  var $target = $(e.target).closest('.timeline');
  var scroll = $target.scrollLeft();

  //var maxScroll = $target.find('ol').width() - $target.width();
  var scrollLeft = timelineSection.scrollLeft;
  var scrollWidth = timelineSection.scrollWidth;

  if (scrollLeft === 0) {
    setBtnState(arrowPrev);
  }
  clearTimeout(checkGradientTimer);
  if (arrowPrev) {
    checkGradientTimer = setTimeout(function() {
      isElementInViewport(firstItem) || scrollLeft === 0
        ? setBtnState(arrowPrev)
        : setBtnState(arrowPrev, false);
      isElementInViewport(lastItem) || scrollLeft >= scrollWidth
        ? setBtnState(arrowNext)
        : setBtnState(arrowNext, false);
      var windowWidth = $window.width();
      if (
        timelineSection.scrollLeft <
        timelineSection.scrollWidth - windowWidth * 2
      ) {
        $('.jump-to-end').removeClass('fade-out');
      } else {
        $('.jump-to-end').addClass('fade-out');
      }

      if (!arrowPrev.disabled) {
        $('.gradient').addClass('has-before');
      } else {
        $('.gradient').removeClass('has-before');
      }
      if (!arrowNext.disabled) {
        $('.gradient').addClass('has-after');
      } else {
        $('.gradient').removeClass('has-after');
      }
    }, 10);
  }
  // if(scroll <= 0) {
  //   if(scroll <= 0 && e.originalEvent.wheelDeltaX >= 0) {
  //     e.preventDefault();
  //   }
  // }
  // if(scroll >= maxScroll) {
  //   if (scroll >1 && e.originalEvent.wheelDeltaX <= 0) {
  //     e.preventDefault();
  //   }
  // }
}, 50);

$(window).scroll(
  throttleAfter(function(event) {
    $('.module').each(function(i, el) {
      var el = $(el);
      if (el.visible(true)) {
        el.addClass('activeSection');
      } else {
        el.removeClass('activeSection');
      }
    });
    // if($tS.visible(false, 120)){
    //   $tS.addClass('fully-visible');
    // }else{
    //   $tS.removeClass('fully-visible');
    // }
  }, 50),
);

var scrollPaused = true;
var scrollTimer = null;
var scrollFalseTimer = null;
var convertYScroll = function _convertScroll(e) {
  if (scrollPaused && e && e.originalEvent && e.originalEvent.deltaY !== 0) {
    var scrollLeft = timelineSection.scrollLeft;
    var scrollWidth = timelineSection.scrollWidth;

    var inContainer =
      e.clientY +
      timelineSection.scrollTop -
      timelineSection.getBoundingClientRect().top;
    //console.log(inContainer>200 && inContainer<300, inContainer);
    // var _top          = $tS.offset().top - 72;
    // var _bottom       = _top + $tS.height() - 72;
    var move =
      scrollLeft + ~~((e.originalEvent.deltaY + e.originalEvent.deltaX) * 2);
    if (move < 0) {
      move = 0;
    }
    if (move > scrollWidth) {
      move = scrollWidth;
    }
    if (
      move >= 0 &&
      move <= scrollWidth &&
      inContainer > 200 &&
      inContainer < 300
    ) {
      timelineSection.scrollLeft = move;
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else {
      clearTimeout(scrollFalseTimer);
      scrollFalseTimer = setTimeout(function() {
        scrollPaused = false;
      }, 100);
    }
  } else {
    clearTimeout(scrollFalseTimer);
    scrollFalseTimer = setTimeout(function() {
      scrollPaused = false;
    }, 50);
  }

  clearTimeout(scrollTimer);
  scrollTimer = setTimeout(function() {
    scrollPaused = true;
  }, 1500);
};

// START
if (timeline) {
  //console.log('timeline');
  $('html').addClass('overscroll-off');
  window.addEventListener('load', init);
  var $body = $('body');

  if ($(window).width() > 991) {
    window.addEventListener('load', timelinePopups);
    $(document).on('mousewheel', handleTLScroll);
    $body.on('mousewheel', '#timeline-section', convertYScroll);
  }

  //allow touchscroll to be cancelable
  // document.addEventListener(
  //   'touchstart',
  //   this.handleTouchStart,
  //   {passive: false}
  // );
  // document.addEventListener(
  //   'touchmove',
  //   this.handleTouchMove,
  //   {passive: false}
  // );

  //throttled scroll event (debounce on trailing edge)
}

function reverseChronology() {
  var $timeline = jQuery(timeline);
  var lis = $timeline.find('.timeline-event');
  $timeline.find('.timeline-intro').after(lis.get().reverse());
  timelineSection.scrollLeft = 0;
}
window.reverseChronology = reverseChronology;

// YEAR FILTER AJAX FINISH
$(document).on('sf:ajaxfinish', '.searchandfilter', function() {
  // Load lightbox or JS scripts again here
  (timeline = document.querySelector('.timeline ol')),
    (timelineSection = document.querySelector('#timeline-section')),
    ($tS = $(timelineSection)),
    (elH = document.querySelectorAll('.timeline li > div')),
    (arrows = document.querySelectorAll('.timeline .arrows .arrow')),
    (arrowPrev = document.querySelector('.timeline .arrows .arrow__prev')),
    (arrowNext = document.querySelector('.timeline .arrows .arrow__next')),
    (firstItem = document.querySelector('.timeline .timeline-intro')),
    (lastItem = document.querySelector('.timeline li:last-child')),
    // xScrolling = ~~((window.innerWidth / 1.5) - 120),
    // disabledClass = "disabled";

    setTimeout(function() {
      //setEqualHeights(elH);
      if ($(window).width() > 991) {
        timelinePopups();
      }
      if (timeline) {
        animateTl(xScrolling, arrows, timeline);
      }
      // if(timelineSection) {
      //   setSwipeFn(timelineSection, arrowPrev, arrowNext);
      // }
      if (arrowPrev) {
        setTimeout(function() {
          isElementInViewport(firstItem) || scrollLeft === 0
            ? setBtnState(arrowPrev)
            : setBtnState(arrowPrev, false);
          isElementInViewport(lastItem) || scrollLeft >= scrollWidth
            ? setBtnState(arrowNext)
            : setBtnState(arrowNext, false);

          if (!arrowPrev.disabled) {
            $('.gradient').addClass('has-before');
          } else {
            $('.gradient').removeClass('has-before');
          }
          if (!arrowNext.disabled) {
            $('.gradient').addClass('has-after');
          } else {
            $('.gradient').removeClass('has-after');
          }
        }, 10);
      }
      setKeyboardFn(arrowPrev, arrowNext);
    }, 150);
});

function init() {
  //setEqualHeights(elH);
  animateTl(xScrolling, arrows, timeline);
  // setSwipeFn(timelineSection, arrowPrev, arrowNext);
  setKeyboardFn(arrowPrev, arrowNext);
}

// SET EQUAL HEIGHTS
function setEqualHeights(el) {
  var counter = 0;
  for (var i = 0; i < el.length; i++) {
    var singleHeight = el[i].offsetHeight;
    if (counter < singleHeight) {
      counter = singleHeight;
    }
  }
  for (var i = 0; i < el.length; i++) {
    el[i].style.height = counter + 'px';
  }
}

// CHECK IF AN ELEMENT IS IN VIEWPORT
// http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  var elLeft = $(el).position().left; // Track left position of first/last item

  return (
    rect.top >= 0 &&
    elLeft >= 0 &&
    //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// SET STATE OF PREV/NEXT ARROWS
function setBtnState(el, flag) {
  //console.log(flag);
  if (!el) {
    return false;
  }
  if (flag !== false) {
    // If end of timeline is reached
    el.classList.add(disabledClass); // Disable appropriate button
    el.disabled = true;
  } else {
    if (el.classList.contains(disabledClass)) {
      el.classList.remove(disabledClass); // Enable appropriate button
    }
    el.disabled = false;
  }
}

//$('html,body').addEventListener("touchstart", function (e) { console.log('touchmove',e) });

// ANIMATE TIMELINE
function animateTl(scrolling, el, tl) {
  var listItems = document.querySelectorAll('.timeline li > div');
  var count = listItems.length;
  // const liFirst = firstItem.getBoundingClientRect(); // Get placement of first timeline item
  // const liEnd = lastItem.getBoundingClientRect(); // Get placement of last timeline item

  if (count > 3) {
    // Setup Timeline scroll if there are more than 3 items
    var counter = 0;
    $tS.on('click', '.arrow__prev,.arrow__next', function(e) {
      // Register a click event listener for the timeline button
      // Each time a button is clicked, we check the disabled state of the timeline buttons
      // And if they aren’t disabled, we disable them. This ensures that both buttons will be clicked only once until the animation finishes.
      var $clicked = $(this);
      if (!arrowPrev.disabled) {
        arrowPrev.disabled = true;
      }
      if (!arrowNext.disabled) {
        arrowNext.disabled = true;
      }

      var sign = $clicked.hasClass('arrow__next') ? '' : '-';

      // var tlStyle = getComputedStyle(tl);
      // var tlTransform = tlStyle.getPropertyValue("-webkit-transform") || tlStyle.getPropertyValue("transform");
      // var values = parseInt(tlTransform.split(",")[4]) + parseInt(sign + scrolling);

      var oldScrollLeft = timelineSection.scrollLeft;
      var move = oldScrollLeft + parseInt(sign + scrolling);

      var scrollWidth = timelineSection.scrollWidth;

      //console.log('scrollLeft', timelineSection, scrollLeft, move);

      if (move < 100) {
        move = 0;
      }
      if (move > scrollWidth - 100) {
        move = scrollWidth;
      }

      $(timelineSection).animate({ scrollLeft: move }, 200);
      //timelineSection.scrollLeft = move;

      // // If it’s the first time an arrow is clicked, we use the transform property to move the timeline 280px to the right.
      // // The value of the xScrolling variable determines the amount of movement.
      // if (counter === 0 && !this.classList.contains("end")) {
      //     //tl.style.transform = 'translateX(-' + scrolling + 'px)';
      //     tl.scrollLeft = move;
      // } else if (counter === 0 && this.classList.contains("end")) {
      //     //console.log('first scroll back');
      //     //const scrollDiff = parseInt(sign + (liEnd.right - liEnd.left));
      //     //tl.style.transform = 'translateX(' + values + 'px)';
      //     arrowPrev.classList.remove('end'); // Remove end class to prev arrow
      // } else if (counter > 0 && this.classList.contains("end")) {
      //     //tl.style.transform = 'translateX(' + values + 'px)';
      //     arrowPrev.classList.remove('end'); // Remove end class to prev arrow
      // } else {
      //     //console.log('last here');
      //     // If we’ve already clicked on a button, we retrieve the current transform value of the timeline and add or remove to that value, const tlStyle = getComputedStyle(tl);
      //     // const tlTransform = tlStyle.getPropertyValue("-webkit-transform") || tlStyle.getPropertyValue("transform"); The desired amount of movement (i.e. 280px).
      //     // So, as long as we click on the previous button, the value of the transform property decreases and the timeline is moved from left to right.
      //     // However, when the next button is clicked, the value of the transform property increases and the timeline is moved from right to left.
      //     tl.style.transform = 'translateX(' + values + 'px)';
      if (move === 0) {
        setBtnState(arrowPrev);
      }

      setTimeout(function() {
        // Button state check
        isElementInViewport(firstItem) || move === 0
          ? setBtnState(arrowPrev)
          : setBtnState(arrowPrev, false);
        isElementInViewport(lastItem) || move >= scrollWidth
          ? setBtnState(arrowNext)
          : setBtnState(arrowNext, false);

        if (!arrowPrev.disabled) {
          $('.gradient').addClass('has-before');
        } else {
          $('.gradient').removeClass('has-before');
        }
        if (!arrowNext.disabled) {
          $('.gradient').addClass('has-after');
        } else {
          $('.gradient').removeClass('has-after');
        }
        if (move + 600 > scrollWidth) {
          $('.jump-to-end').addClass('fade-out');
        } else {
          $('.jump-to-end').removeClass('fade-out');
        }
      }, 251);

      counter++;
    }); // End on click

    setTimeout(function() {
      // Button state check on load
      //console.log('last item state is:' + lastItemState);

      var sL = timelineSection.scrollLeft;
      var scrollWidth = timelineSection.scrollWidth;

      isElementInViewport(firstItem) || sL === 0
        ? setBtnState(arrowPrev)
        : setBtnState(arrowPrev, false);
      isElementInViewport(lastItem) || sL >= scrollWidth
        ? setBtnState(arrowNext)
        : setBtnState(arrowNext, false);

      if (!arrowPrev.disabled) {
        $('.gradient').addClass('has-before');
      } else {
        $('.gradient').removeClass('has-before');
      }
      if (!arrowNext.disabled) {
        $('.gradient').addClass('has-after');
      } else {
        $('.gradient').removeClass('has-after');
      }
    }, 250);
  } // End timeline being setup with more than 3 items

  // Jump to button
  var tlEnd = lastItem.getBoundingClientRect(); // Get placement of last timeline item
  var scrollAmount = tlEnd.left - 520; // Adjust slightly so not at complete end

  $('.jump-to-end a').click(function() {
    //console.log('jump to clicked');
    //console.log(scrollAmount);
    //tl.style.transform = 'translateX(-' + scrollAmount + 'px)';
    var sL = timelineSection.scrollLeft;
    var scrollWidth = timelineSection.scrollWidth;
    $(timelineSection).animate({ scrollLeft: scrollWidth }, 100);

    $('.jump-to-end').addClass('fade-out');

    if (count > 3) {
      setTimeout(function() {
        // Timeline animation needs .5s second to complete. As long as it completes, we wait for 50 milliseconds and then, we perform our checks.
        isElementInViewport(firstItem) || sL === 0
          ? setBtnState(arrowPrev)
          : setBtnState(arrowPrev, false);
        isElementInViewport(lastItem) || sL >= scrollWidth
          ? setBtnState(arrowNext)
          : setBtnState(arrowNext, false);
        arrowPrev.classList.add('end'); // Add class to prev arrow
        arrowPrev.classList.remove('disabled');
        arrowNext.classList.add(disabledClass); // Disable next arrow

        arrowNext.disabled = true;
        arrowPrev.disabled = false;
        if (!arrowPrev.disabled) {
          $('.gradient').addClass('has-before');
        } else {
          $('.gradient').removeClass('has-before');
        }
        if (!arrowNext.disabled) {
          $('.gradient').addClass('has-after');
        } else {
          $('.gradient').removeClass('has-after');
        }
      }, 250);
    }
  });
}

// ADD SWIPE SUPPORT FOR TOUCH DEVICES
// function setSwipeFn(tl, prev, next) {
//   if (tl) {
//     var hammer = new Hammer(tl);
//     hammer.on("swipeleft", function () { next.click() });
//     hammer.on("swiperight", function () { prev.click() });
//   }
// }

// ADD BASIC KEYBOARD FUNCTIONALITY
function setKeyboardFn(prev, next) {
  document.addEventListener('keydown', function(e) {
    var isActive = $tS.closest('.timeline-module').hasClass('activeSection');
    if ((isActive && e.which === 37) || e.which === 39) {
      //var timelineOfTop = timeline.offsetTop;
      //var y = window.pageYOffset;
      // if (timelineOfTop !== y) {
      //   window.scrollTo(0, timelineOfTop);
      // }
      if (e.which === 37) {
        prev.click();
      } else if (e.which === 39) {
        next.click();
      }
    }
  });
}

// Timeline Popup Action - Dynamically created popups

function timelinePopups() {
  $('.timeline-event').on('click', function(e) {
    //console.log('event clicked');
    e.preventDefault();
    var paragraph = $(this)
      .find('p.height')
      .text();
    var theGoodStuff = '<h2>' + paragraph + '</h2>';

    var id = $(this).attr('id'); // clicked collusion id

    collusionJSON
      .done(function(data) {
        // now you can read the data
        // console.log(data);

        // json success continues to run
        var mysearchId = id; // clicked collusion id

        for (var i = 0; i < data.length; i++) {
          // run through json data ids
          if (data[i].id == mysearchId) {
            // if json id matches clicked collusion id
            // display data of collusion clicked
            // console.log(data[i]);
            var title = data[i]['title'];
            var link = data[i]['link'];
            var date = data[i]['date'];
            var excerpt = data[i]['excerpt'];
            var featured_image = data[i]['featured_image'];
            var players = data[i]['players'];
            var terms = data[i]['terms'];

            var popupFormat = '';

            /*              $.each(categories, function(key, value){
                          var name = categories[key].playerName;
                          var image = categories[key].playerImage;
                          console.log("Name: " + name + "; Image: " + image);
                        });*/

            popupFormat = "<div class='popup-collusion'>";

            popupFormat +=
              "<div class='social-nav fixed'><div class='social-side'><div class='box'><i class='fa fa-share-alt' aria-hidden='true'></i></div><div class='share-slide'><div class='social links'>";
            popupFormat +=
              "<ul><li class='facebook'><a href='http://www.facebook.com/sharer.php?u=" +
              link +
              "' class='social-pop' target='_blank'><i class='fa fa-facebook' aria-hidden='true'></i></a></li>";
            popupFormat +=
              "<li class='twitter'><a href='http://twitter.com/intent/tweet?text=" +
              link +
              "' class='social-pop' target='_blank'><i class='fa fa-twitter' aria-hidden='true'></i></a></li>";
            popupFormat += '</ul></div></div></div></div>';

            popupFormat +=
              "<div class='page-meta'><span>" + date + '</span></div>';
            popupFormat += '<h3>' + title + '</h3>';
            if (featured_image != null) {
              popupFormat +=
                "<div class='popup-image-container'><div class='popup-image' style='background: url(" +
                featured_image +
                ")'></div></div>";
            }
            if (excerpt != null) {
              popupFormat += '<p>' + excerpt + '</p>';
            }
            if (link != null) {
              popupFormat +=
                "<h6 class='learn-more'><a href='" +
                link +
                "'>Learn More <div class='arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.06 44'><defs></defs><title>Vector Smart Object</title><path class='cls-1' d='M54.06,22l-1.81-2-18-20L29.78,4l13.5,15H0v6H43.28L29.78,40l4.47,4,18-20Z'/></svg></div></a></h6>";
            }
            if (players != null) {
              popupFormat += "<div class='involved'>";
              $.each(players, function(key, value) {
                var name = players[key].playerName;
                var image = players[key].playerImage;
                var slug = players[key].playerSlug;
                //console.log("Name: " + name + "; Image: " + image);
                popupFormat += "<div class='collusion-person'>";
                popupFormat +=
                  "<div class='profile-container'><div class='profile-image' style='background: url(" +
                  image +
                  ") no-repeat center center'></div></div>";
                popupFormat += '<h6>' + name + '</h6>';
                popupFormat +=
                  "<a href='/players/" + slug + "' class='full-link'></a>";
                popupFormat += '</div>';
              });
              popupFormat += '</div>';
            }
            if (terms != null) {
              popupFormat += "<div class='tags'>";
              $.each(terms, function(key, value) {
                var tagName = terms[key].tagName;
                var tagSlug = terms[key].tagSlug;
                //console.log("Name: " + tagName + "; Slug: " + tagSlug);
                var siteURL = window.location.hostname;
                var siteHTTP = window.location.protocol;
                popupFormat += "<div class='collusion-tag'>";
                popupFormat +=
                  "<a href='" +
                  siteHTTP +
                  '//' +
                  siteURL +
                  '/tags/' +
                  tagSlug +
                  "' class='full-link'></a><i class='fa fa-tag' aria-hidden='true'></i>";
                popupFormat += '<h6>' + tagName + '</h6>';
                popupFormat += '</div>';
              });
              popupFormat += '</div>';
            }
            popupFormat += '</div>';

            // display popup with collusion data
            $.magnificPopup.open({
              items: {
                src: popupFormat,
              },
              type: 'inline',
              mainClass: 'mfp-fade timeline-popup',
              closeMarkup:
                '<button title="%title%" class="mfp-close"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><polygon points="97,10.5 89.6,3.1 50.1,42.6 50.1,42.6 50,42.6 10.4,3.1 3,10.5 42.5,50 3,89.5 10.4,96.9 50,57.4 50.1,57.4 50.1,57.4 89.6,96.9 97,89.5 57.5,50 "/></svg></button>',
            });

            return;
          }
        }
      })

      .fail(function() {
        console.log('not found!');
        $('body').append('<p>Oh no, something went wrong!</p>');
      });
  });
} // End timelinePopups

//Instructions popup
if (document.getElementById('timelineInstructions') !== null) {
  $(window).load(function() {
    $.magnificPopup.open(
      {
        items: {
          src: '#timelineInstructions',
        },
        type: 'inline',
        showCloseBtn: true,
        mainClass: 'mfp-fade instructions-popup',
        closeMarkup:
          '<button title="%title%" class="mfp-close"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><polygon points="97,10.5 89.6,3.1 50.1,42.6 50.1,42.6 50,42.6 10.4,3.1 3,10.5 42.5,50 3,89.5 10.4,96.9 50,57.4 50.1,57.4 50.1,57.4 89.6,96.9 97,89.5 57.5,50 "/></svg></button>',
      },
      0,
    );
  });

  $('#close-modal').on('click', function() {
    $.magnificPopup.close();
  });
}
