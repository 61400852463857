/* global $, Cookies */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 1; // delay any scroll events by this many MS
const $topNav = $('.top');
const $html = $('html');
let topNavHeight = $topNav.height(); // eslint-disable-line no-unused-vars

// eslint-disable-next-line no-unused-vars
const positionStickySupport = (function() {
  const el = document.createElement('a');
  const mStyle = el.style;
  mStyle.cssText =
    'position:sticky;position:-webkit-sticky;position:-ms-sticky;';
  return mStyle.position.indexOf('sticky') !== -1;
})();

$html.toggleClass('nopositionsticky', !positionStickySupport);

// alert bar
const $alertBar = $('body').find('.alert-bar');
let alertBarHeight = $alertBar.height() || 0;

// handle cases where the alert bar is taller than the viewport: falls
// back to position: relative
function stickyAlertCheck() {
  alertBarHeight = $alertBar.height() || 0;
  const isSticky = $alertBar.css('position') === 'sticky';
  if (alertBarHeight > $(window).height() && isSticky) {
    $alertBar.css({ position: 'relative' });
  } else if (alertBarHeight <= $(window).height() && !isSticky) {
    $alertBar.css({ position: 'sticky' });
  }
}

// this check relies on the alert.js code being in the site <head> tag,
// or at least running before these main scripts
if ($html.hasClass('ok-to-alert')) {
  $('body').on('click', '.alert-bar__close', () => {
    alertBarHeight = 0;
    $alertBar.slideToggle('fast', () => {
      $alertBar.prop('hidden', true);
      topNavHeight = $topNav.height();
    });

    if (window.ALERT_OPTIONS && window.ALERT_OPTIONS.alert_is_cookied) {
      Cookies.set('alertbar', window.ALERT_OPTIONS.cookievalue || 'value', {
        expires: window.ALERT_OPTIONS.expires || 7,
      });
    }
  });

  // make sure the paralax gets switched off if the alert bar is taller
  // than the screen height

  if (positionStickySupport) {
    $(window).resize(stickyAlertCheck);
    stickyAlertCheck();
  }
}

// addSticky :: Int -> () -> IO (side-effect, adds or removes class)
// eslint-disable-next-line no-unused-vars
let canSticky = true;
const checkScroll = (e = { nonEvent: true }) => {
  const { target = {}, nonEvent = false } = e;
  let { scrollingElement: { scrollTop } = {} } = target;

  if (!scrollTop) {
    scrollTop = $(window).scrollTop();
  }
  alertBarHeight = $alertBar.height() || 0;
  const alertPusNavSticky = topNavHeight + alertBarHeight;

  $html.toggleClass('scrolled-past-nav', scrollTop >= alertPusNavSticky);

  if (canSticky) {
    $topNav.toggleClass('is-sticky', scrollTop >= alertPusNavSticky);
    canSticky = false;
    setTimeout(() => {
      canSticky = true;
      if (!nonEvent) {
        checkScroll();
      }
    }, 303);
  } else {
    canSticky = true;
  }

  if (positionStickySupport) {
    $alertBar.toggleClass('social-hide', scrollTop < alertPusNavSticky);
  }
};
const addSticky = throttleDomAfterAsync(checkScroll, scrollEffectDelay);

$(window).on('scroll', addSticky);
addSticky();
