/* global $, TweenMax, TimelineMax, Power1, ScrollMagic */

const animationBreakpoint = 960; // see matching breakpoint in global/effects/animations.scss

// Static Animations, they will run once the site is loaded
const home = new TimelineMax();
home
  .to('.topper-video video', 0.5, { opacity: 1 }, 0)
  .to('.topper .container, .topper .container-small', 0, { opacity: 1 }, 0)
  .to('.overlay__inner', 0.5, { opacity: 0.4, ease: Power1.easeInOut })
  .staggerTo('.topper .topper__center, .topper__end', 0.35, {
    opacity: 1,
    ease: Power1.easeInOut,
  })
  .staggerFrom(
    '.topper h6, .topper h1, .topper h2, .topper .deck',
    0.35,
    { opacity: 0, yPercent: 3, ease: Power1.easeInOut },
    0.18,
  )
  .staggerFrom(
    '.topper .video-link, .topper .topper-cta a, .topper .donation, .topper .values__scroll-links',
    0.35,
    { opacity: 0, ease: Power1.easeInOut },
  )
  .staggerFrom('.topper form', 0.35, {
    opacity: 0,
    scale: 1.1,
    ease: Power1.easeInOut,
  })
  .staggerTo('.home-history .line__outer', 0.35, {
    opacity: 1,
    ease: Power1.easeInOut,
  })
  .staggerFrom('.home-history .read-more', 0.35, {
    opacity: 0,
    yPercent: -15,
    scale: 1.05,
    ease: Power1.easeInOut,
  })
  .staggerTo('.history__box', 0.35, {
    opacity: 1,
    ease: Power1.easeInOut,
  })
  .staggerFrom(
    '.history__box p, .history__box a, .history__box .history__tag, .download__outer',
    0.35,
    {
      opacity: 0,
      yPercent: -15,
      ease: Power1.easeInOut,
    },
    0.25,
  )
  .staggerFrom('.history__box:after, .download__outer:after', 0.35, {
    opacity: 0,
    yPercent: -15,
    ease: Power1.easeInOut,
  });
home.play();

// SCROLL MAGIC STARTS
const controller = new ScrollMagic.Controller();

if ($(window).width() >= animationBreakpoint) {
  if ($('.issues-grid').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.issues-grid',
      reverse: false,
      offset: 0,
      triggerHook: 1,
    })
      .setTween(
        TweenMax.staggerTo(
          '.issues-grid .single-issue',
          0.25,
          { opacity: 1 },
          0.15,
        ),
      )
      // .addIndicators()
      .addTo(controller);
  }

  if ($('.news-items').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.news-items',
      reverse: false,
      offset: 0,
      triggerHook: 1,
    })
      .setTween(
        TweenMax.staggerTo(
          '.news-items .news-item',
          0.25,
          { opacity: 1 },
          0.15,
        ),
      )
      // .addIndicators()
      .addTo(controller);
  }

  if ($('.news-feed .container').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.news-feed .container',
      reverse: false,
      offset: 100,
      triggerHook: 1,
    })
      .setTween(
        TweenMax.staggerTo(
          '.news-items h1, .news-feed .social-links ul li',
          0.35,
          {
            opacity: 1,
            yPercent: -15,
          },
          0.25,
        ),
      )
      // .addIndicators()
      .addTo(controller);
  }

  const cta = new TimelineMax();
  cta
    .staggerFrom(
      '.bottom-cta h6, .bottom-cta h1',
      0.35,
      { opacity: 0, yPercent: 3, ease: Power1.easeInOut },
      0.2,
    )
    .staggerFrom('.bottom-cta .footer__form', 0.45, {
      opacity: 0,
      scale: 1.08,
      ease: Power1.easeInOut,
    });

  if ($('.bottom-cta .container').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.bottom-cta .container',
      reverse: false,
      offset: 100,
      triggerHook: 1,
    })
      .setTween(cta)
      // .addIndicators()
      .addTo(controller);
  }

  const homeValues = new TimelineMax();
  homeValues
    .staggerFrom('.home-value-areas .value', 0.35, { opacity: 0 }, 0.2)
    .set(
      '.home-value-areas .value__image',
      { delay: 0.5, className: '+=animate' },
      0.25,
    )
    .set(
      '.home-value-areas .value__image',
      { delay: 0.5, className: '+=animate-shadow' },
      0.25,
    );

  if ($('.home-value-areas .container').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.home-value-areas .container',
      reverse: false,
      offset: 0,
      triggerHook: 1,
    })
      .setTween(homeValues)
      // .addIndicators()
      .addTo(controller);
  }

  const homeFeaturedAction = new TimelineMax();
  homeFeaturedAction
    .staggerFrom('.home-featured-action .featured-action__img', 0.35, {
      opacity: 0,
    })
    .set(
      '.home-featured-action .featured-action__img',
      { delay: 0.5, className: '+=animate' },
      0.25,
    )
    .staggerTo('.featured-action__box', 0.35, {
      opacity: 1,
      ease: Power1.easeInOut,
    })
    .staggerFrom(
      '.featured-action__box h3, .featured-action__box p, .featured-action__box .button-wrapper, .featured-action__box .featured-action__tag',
      0.35,
      {
        opacity: 0,
        yPercent: -15,
        ease: Power1.easeInOut,
      },
      0.25,
    );

  if ($('.home-featured-action .container').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.home-featured-action .container',
      reverse: false,
      offset: 100,
      triggerHook: 1,
    })
      .setTween(homeFeaturedAction)
      // .addIndicators()
      .addTo(controller);
  }

  const homeCoalition = new TimelineMax();
  homeCoalition
    .staggerFrom('.home-coalition h1, .home-coalition a', 0.35, {
      opacity: 0,
      yPercent: -15,
    })
    .staggerFrom(
      '.home-coalition .coalition__update',
      0.35,
      {
        opacity: 0,
        ease: Power1.easeInOut,
      },
      0.25,
    );

  if ($('.home-coalition .container').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.home-coalition .container',
      reverse: false,
      offset: 100,
      triggerHook: 1,
    })
      .setTween(homeCoalition)
      // .addIndicators()
      .addTo(controller);
  }

  const valueContainerAnnimation = new TimelineMax();
  valueContainerAnnimation
    .staggerFrom(
      '.value__row .value-image__container',
      0.35,
      {
        opacity: 0,
        ease: Power1.easeInOut,
      },
      0.25,
    )
    .staggerFrom(
      '.value__row .value-text__container h1, .value__row .value-text__container nav ul li',
      0.35,
      {
        opacity: 0,
        yPercent: -15,
      },
    )
    .staggerFrom(
      '.value__row .container-small .col-md-6',
      0.35,
      {
        opacity: 0,
        ease: Power1.easeInOut,
      },
      0.25,
    );

  if ($('.module-values .value__row').length > 0) {
    new ScrollMagic.Scene({
      triggerElement: '.module-values .value__row',
      reverse: false,
      offset: 100,
      triggerHook: 1,
    })
      .setTween(valueContainerAnnimation)
      // .addIndicators()
      .addTo(controller);
  }
}
