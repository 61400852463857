/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 0; // delay any scroll events by this many MS
const $modules = $('body').find('[data-module],.module');

// add class to last module
$modules.last().addClass('last-module');

const checkActiveSections = throttleDomAfterAsync(() => {
  $modules.each((i, el) => {
    const $el = $(el);
    $el.addClass('seenSection').toggleClass('activeSection', $el.visible(true));
  });
}, scrollEffectDelay);

$(window).on('scroll', checkActiveSections);

// Staff page
$('.staff-member').on('click', (e) => {
  $(e.currentTarget)
    .find('.tabs')
    .show();
});

$('.headshot-tab').on('click', (e) => {
  console.log(e.currentTarget);
  $(e.currentTarget)
    .find('.tabs')
    .show();
  $(e.currentTarget).removeClass('current');
  $(e.currentTarget)
    .siblings('.bio-tab')
    .addClass('current');
});

$('.tabs li').on('click', (e) => {
  const tabId = $(e.target).attr('data-tab');
  const uniqueId = $(e.target).attr('data-unique');

  $('.tabs-' + uniqueId + ' li').removeClass('current');
  $('.staff-' + uniqueId + ' .tab').removeClass('current');

  $(e.target).addClass('current');
  $('.' + tabId).addClass('current');
});

$('.staff-member').on('mouseleave', (e) => {
  $(e.currentTarget)
    .find('.tabs li')
    .removeClass('current');
  $(e.currentTarget)
    .find('.tab')
    .removeClass('current');
  $(e.currentTarget)
    .find('.tabs .bio')
    .addClass('current');
  $(e.currentTarget)
    .find('.headshot-tab')
    .addClass('current');
  $(e.currentTarget)
    .find('.tabs')
    .hide();
});

// Landing page
$('body')
  .on('mouseenter', '.scroll', function() {
    $(this)
      .next('.tool')
      .css('opacity', '1');
  })
  .on('mouseleave', '.scroll', function() {
    $(this)
      .next('.tool')
      .css('opacity', '0');
  })
  .on('click', '.scroll', function(e) {
    e.preventDefault();
    const scrollAnchor = $(this).attr('data-scroll');
    const scrollTop =
      $('.module[data-anchor="' + scrollAnchor + '"]').offset().top - 72;

    $('html, body').animate({ scrollTop }, 500);
  });

// scopeOfStates table tfooter calculation
document.addEventListener('DOMContentLoaded', () => {
  const table = document.querySelector('.scopeOfStates');
  const footer = table.querySelector('tfoot tr');
  const tbody = table.querySelector('tbody');
  const rows = tbody.querySelectorAll('tr');

  for (let i = 1; i < footer.cells.length - 1; i++) {
    let colCount = 0;
    rows.forEach((row) => {
      const cell = row.cells[i];
      if (cell.textContent.trim().toLowerCase() === 'yes') {
        colCount++;
      }
    });
    footer.cells[i].textContent = colCount;
  }
});
